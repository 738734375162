export default {
  namespaced: true,
  state: {
    crowser: null,
    crowserInited: false,
  },
  getters: {},
  mutations: {
    setCrowser(state, obj) {
      console.log("setCrowser");
      state.crowser = obj;
      state.crowserInited = true;
    },
  },
  actions: {},
};
