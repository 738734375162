<template>
  <div>
    <h1>My Scripts</h1>
    <div v-for="script in $store.state.userScripts" :key="script.id">
      <router-link :to="{ name: 'editor', params: { id: script.key } }">
        {{ script.key }} {{ script.data.name }} -
        {{ script.data.updatedDate.toDate().toLocaleString() }}
      </router-link>
    </div>
    <!-- {{ $store.state.userScripts }} -->
  </div>
</template>

<script>
export default {
  name: "ScriptList",
  mounted() {
    this.$store.dispatch("loadMyScripts");
  },
};
</script>

<style></style>
