<template>
  <div class="header">
    <div class="left">
      <span class="heading-1">fishcrow </span>
      <span>{{ $store.getters["fileName"] }}</span>
    </div>

    <div class="center">
      <div>
        <router-link :to="{ name: 'home' }"> <button>Home</button></router-link>
        <button @click="loadFromCrow">Load File from Crow</button>
        <button @click="run">Run on Crow</button>
        <button @click="install">Install and Run on Crow</button>
      </div>
      <!-- <div>
        <span v-if="$store.state.fileText == ''">
          <button @click="loadFile">Open File</button>
          <button @click="saveFileAsNew">New File</button>
          <button @click="loadFromCrow">Load File from Crow</button>
        </span>

        <span v-else>
          <button v-if="$store.state.fileHandle" @click="saveFile">Save</button>
          <button @click="saveFileAsNew">Save as</button>
          <button @click="run">Run on Crow</button>
          <button @click="install">Install and Run on Crow</button>
          <button @click="saveToDatabase">Save to DB</button>
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      version: process.env.VUE_APP_WARBLER_VERSION,
    };
  },
  computed: {
    title() {
      return "";
    },
  },
  mounted() {
    this.$store.dispatch("connectToFirestore");
  },
  methods: {
    startFunction() {
      this.$emit("startFunction");
    },
    closeContext() {
      this.$emit("closeContext");
    },
    loadFile() {
      this.$store.dispatch("openFile");
    },
    saveFile() {
      this.$store.dispatch("saveFile");
    },
    saveFileAsNew() {
      this.$store.dispatch("saveFileAsNew");
    },
    saveToDatabase() {
      this.$store.dispatch("authenticateWithGithub");
    },
    run() {
      this.$emit("runOnCrow");
    },
    install() {
      this.$emit("installAndRun");
    },
    loadFromCrow() {
      this.$emit("loadFromCrow");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  // height: 54px;
  background-color: lightblue;
}

.heading-1 {
  font-size: 2em;
  font-weight: bold;
}

.left {
  position: absolute;
  left: 12px;
  top: 6px;
  h1 {
    font-size: 2em;
    line-height: 0px;
  }
}

@media only screen and (max-width: 760px) {
  .left {
    visibility: hidden;
  }
}

.right {
  position: absolute;
  right: 12px;
  top: 11px;
}

.center {
  position: absolute;
  top: 11px;
  width: 100%;
  display: flex;
  justify-content: center;
  div {
  }
}

// @media only screen and (max-width: 1000px) {
//   .small-only {
//     visibility: visible;
//   }
//   .large-only {
//     visibility: hidden;
//   }
// }

button {
  background-color: black;
  margin-left: 5px;
  border: none;
  cursor: pointer;
  color: white;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
}
label {
  margin-left: 16px;
  margin-top: 6px;
}
input {
  width: 140px;
  margin-left: 2px;
  margin-right: 20px;
  // height: 28px;
  padding: 6px 10px;
  // color: white;
  // background-color: #666;
  // margin: 8px 0;
  // box-sizing: border-box;
}
</style>
