<template>
  <div>
    <h1>Account</h1>
    <div v-if="$store.state.isAuthenticated">
      Logged in {{ $store.state.user.email }}
    </div>
    <div v-else>Not logged in</div>
  </div>
</template>

<script>
export default {
  computed: {},
};
</script>

<style></style>
