import Vue from "vue";
import Router from "vue-router";
import EditorContainer from "@/components/EditorContainer";
import NotFound from "@/pages/NotFound";
import Account from "@/pages/Account";
import Home from "@/pages/Home";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/editor/:id",
      name: "editor",
      component: EditorContainer,
    },
    {
      path: "/account",
      name: "account",
      component: Account,
    },
    {
      path: "/",
      name: "home",
      component: Home,
    },
    { path: "*", component: NotFound },
  ],
});
