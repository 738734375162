<template>
  <div class="editor-component">
    <Header
      class="header"
      @loadFromCrow="crowser.getCurrentScript()"
      @installAndRun="crowser.writeFile(text, true)"
      @runOnCrow="crowser.writeFile(text)"
    />
    <div class="display">
      <span v-if="crowser.firmwareVersion">
        Firmware version: {{ crowser.firmwareVersion }}
      </span>
      <span>
        <button v-if="!connected" @click="connect">Connect</button>
        <button v-if="connected" @click="disconnect">Disconnect</button>
      </span>
      <div v-if="crowser.volts && connected">
        <Waveform
          :width="140"
          :height="80"
          name="input 1"
          :value="crowser.volts[0]"
        />
        <Waveform
          :width="140"
          :height="80"
          name="input 2"
          :value="crowser.volts[1]"
        />
        <Waveform
          :width="140"
          :height="80"
          name="output 1"
          :value="crowser.volts[2]"
        />
        <Waveform
          :width="140"
          :height="80"
          name="output 2"
          :value="crowser.volts[3]"
        />
        <Waveform
          :width="140"
          :height="80"
          name="output 3"
          :value="crowser.volts[4]"
        />
        <Waveform
          :width="140"
          :height="80"
          name="output 4"
          :value="crowser.volts[5]"
        />
      </div>
    </div>
    <div ref="editor" :style="editorStyle" class="editor" />
    <Footer class="footer" />
  </div>
</template>

<script>
import * as monaco from "monaco-editor";
// import { script2 } from "@/script";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Waveform from "@/components/Waveform";
import Crowser from "@/crowser";
export default {
  name: "Editor",
  components: {
    Header,
    Footer,
    Waveform,
  },
  data() {
    return {
      windowWidth: 100,
      windowHeight: 1000,
      text: "",
      editor: null,
      editorLeft: 400,
      headerHeight: 54,
      footerHeight: 40,
      crowser: {},
      id: null,
      connected: false,
    };
  },
  computed: {
    editorStyle() {
      let height = this.windowHeight - this.headerHeight - this.footerHeight;
      let width = this.windowWidth - this.editorLeft;
      return {
        left: `${this.editorLeft}px`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: "black",
      };
    },
  },
  mounted() {
    this.text = this.$store.state.scriptDoc.scriptText;
    this.id = this.$route.params.id;
    this.createEditor();
    if (!this.$store.state.serial.crowserInited) {
      this.crowser = new Crowser();
      this.setCrowserListeners();
      this.crowser.init();
      this.$store.commit("serial/setCrowser", this.crowser);
    } else {
      this.crowser = this.$store.state.serial.crowser;
      this.setCrowserListeners();
    }
  },
  methods: {
    connect() {
      console.log("connect");
      this.crowser.connect();
    },
    disconnect() {
      console.log("disconnect");
      this.crowser.disconnect();
    },
    setCrowserListeners() {
      let _this = this;
      this.crowser.onPortConnected = (port) => {
        console.log("onPortConnected", port);
        _this.connected = true;
      };
      this.crowser.onScriptReceived = (value) => {
        console.log("onScriptReceived", value);
        this.editor.getModel().setValue(value);
        this.$store.commit("setFileText", value);
      };
      this.crowser.onForcedForget = () => {
        _this.connected = false;
      };
    },
    emitChange() {
      console.log("emit change");
      let _this = this;
      this.editor
        .getAction("editor.action.formatDocument")
        .run()
        .then(() => {
          console.log("format complete, emit change now");
          console.log(_this.text);
          this.$store.dispatch("updateScriptToFirestore", {
            id: _this.id,
            scriptText: _this.text,
          });
        })
        .catch((e) => {
          console.log("format error");
          console.log(e.name, e.message, e.stack, e.lineNumber);
          // _this.hasError = true;
          // _this.errorName = e.name;
          // if (e.stack.includes("<anonymous>:")) {
          //   let line = parseInt(
          //     e.stack.split("<anonymous>:")[1].split(":")[0],
          //     10
          //   );
          //   _this.errorLine = line - 2;
          // }

          // _this.errorMessage = e.message;
          // // Restore previous Tone context if available.
          // Tone.setContext(existingContext);
          // _this.toneContext = existingContext;
        });
    },
    resize() {
      // Triggered every resize.
      console.log("resize");
      this.windowWidth = document.documentElement.clientWidth;
      this.windowHeight = window.innerHeight;

      var _this = this;
      this.$nextTick(function () {
        _this.editor.layout();
      });
    },
    createEditor() {
      console.warn("createEditor");

      const el = this.$refs.editor;
      const model = monaco.editor.createModel(this.text, "lua");
      this.editor = monaco.editor.create(el, {
        model,
        theme: "vs-dark",
        scrollBeyondLastLine: false,
        multiCursorModifier: "ctrlCmd",
        minimap: {
          enabled: false,
        },
        lineNumbers: "on",
      });
      this.$store.commit("setEditorReference", this.editor);

      // Theme override.
      monaco.editor.defineTheme("transparentBgTheme", {
        base: "vs-dark",
        inherit: true,

        rules: [{ background: "000000" }],
        colors: {
          "editor.background": "#000000",
          // "editorInlayHint.foreground": "#00FF00",
          // "editorInlayHint.background": "#FF00FF",
          // "editor.hoverHighlightBackground": "pink"
          peekWidgetDefaultFocus: "tree",
        },
      });
      monaco.editor.setTheme("transparentBgTheme");

      // Add prettier as formatter.
      monaco.languages.registerDocumentFormattingEditProvider("lua", {
        async provideDocumentFormattingEdits(model, options, token) {
          console.log(options, token);
          const prettier = await import("prettier/standalone");
          const lua = await import("@prettier/plugin-lua");
          const text = prettier.format(model.getValue(), {
            parser: "lua",
            plugins: [lua],
            singleQuote: false,
            printWidth: 80,
          });

          return [
            {
              range: model.getFullModelRange(),
              text,
            },
          ];
        },
      });

      // Remove quickfix shortcut so you can reassign it to something else ...
      // Not sure how this would apply to Crow. It's used as "stop all sounds"
      // in other CodeWarbler projects.
      // https://github.com/microsoft/monaco-editor/issues/102#issuecomment-701704517
      this.editor._standaloneKeybindingService.addDynamicKeybinding(
        "-editor.action.quickFix",
        null,
        () => {}
      );

      this.editor.onDidChangeModelContent(() => {
        const value = this.editor.getValue();
        if (this.text !== value) {
          // console.log(value, event);
          this.text = value;
          // this.parseSyntax();
          // If you add syntax parsing, do it here.
        }
      });

      this.editor.onDidChangeCursorPosition(() => {
        // console.log(this.editor.getPosition().lineNumber);
        this.cursorLineNumber = this.editor.getPosition().lineNumber;
      });

      var _this = this;
      /*
      Quick keys:
        Save/run on CMD-S or CMD-Enter
        Stop on CMD-.
      */
      this.editor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
        function () {
          console.warn("HIGHLIGHT SEND pressed!");
          var selection = _this.editor
            .getModel()
            .getValueInRange(_this.editor.getSelection());
          console.log(selection);
          _this.crowser.write(selection);
        }
      );

      this.editor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S,
        function () {
          console.warn("SAVE pressed!");
          _this.emitChange();
        }
      );

      this.editor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.US_DOT,
        function () {
          console.warn("Stop pressed!");
        }
      );

      window.addEventListener("resize", () => {
        _this.resize();
      });
      _this.resize();
    },
  },
};
</script>

<style lang="scss">
.editor-component {
  position: absolute;
  left: 0px;
  height: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  background-color: teal;
  p {
    button {
      margin-left: 5px;
    }
    select {
      margin-left: 5px;
    }
  }
  .visuals {
    margin: 10px;
    position: absolute;
    display: grid;
    gap: 10px;
    div {
      position: relative;
    }
    .error {
      padding-left: 20px;
      h1 {
        font-size: 1.4em;
        line-height: 2px;
      }
      p {
        font-size: 0.9em;
      }
      position: absolute;
      top: 80px;
      width: 100%;
      background-color: rgba(255, 255, 0, 0.94);
      padding-bottom: 30px;
    }
    .active {
      outline: 3px solid orange;
    }
  }
  .editor {
    position: absolute;
    .lineHighlightDecoration {
      background: lightblue;
      background: orange;
      width: 3px !important;
      margin-left: 8px;
    }
  }
  .footer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 40px;
    width: 100%;
    overflow: hidden;
  }
  .header {
    height: 54px;
  }
  .display {
    position: fixed;
    width: 400px;
    background-color: pink;
  }
}
</style>
