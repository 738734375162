import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";

import serial from "@/store/modules/serial";

import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GithubAuthProvider,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { collection, addDoc, setDoc, doc, getDoc } from "firebase/firestore";

Vue.use(Vuex);

// import { fileOpen, supported, fileSave } from "browser-fs-access";
import { fileOpen, supported } from "browser-fs-access";
import { query, orderBy, limit, where, getDocs } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDFAZNBrw1Ap8TIkkNuIWBDLme3NgKgcdQ",
  authDomain: "fish-crow.firebaseapp.com",
  projectId: "fish-crow",
  storageBucket: "fish-crow.appspot.com",
  messagingSenderId: "115699653858",
  appId: "1:115699653858:web:566d3fe0c2ee60d69752bb",
};

const limitScriptLoadNum = 20;

export default new Vuex.Store({
  modules: { serial },
  state: {
    portConnected: false,
    fileHandle: null,
    fileText: "",
    fileSystemProjectId: "crowser",
    editorReference: null,
    db: null,
    connected: false,
    user: null,
    isAuthenticated: false,
    userScripts: [],
    scriptLoaded: false,
    scriptDoc: null,
  },
  mutations: {
    setPortConnected(state, value) {
      state.portConnected = value;
    },
    setFileText(state, value) {
      state.fileText = value;
    },
    setEditorReference(state, value) {
      state.editorReference = value;
    },
  },
  getters: {
    fileName(state) {
      return state.fileHandle?.name;
    },
  },
  actions: {
    connectToFirestore({ state }) {
      const app = initializeApp(firebaseConfig);

      // Initialize Firebase Authentication and get a reference to the service
      const auth = getAuth(app);

      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          // const uid = user.uid;
          state.user = user;
          state.isAuthenticated = true;
          console.log("user", user);
          // ...
        } else {
          // User is signed out
          // ...
          console.log("not user");
          state.user = null;
          state.isAuthenticated = false;
        }
      });

      // Set the connection to the DB.
      state.db = getFirestore(app);
      state.connected = true;
    },
    async logout() {
      const auth = getAuth();
      await auth.signOut();
      console.log("logged out");
    },
    authenticateWithGithub() {
      console.log("authenticateWithGithub");
      const provider = new GithubAuthProvider();
      provider.addScope("");

      const auth = getAuth();

      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return signInWithPopup(auth, provider)
            .then((result) => {
              // This gives you a GitHub Access Token. You can use it to access the GitHub API.
              const credential =
                GithubAuthProvider.credentialFromResult(result);
              const token = credential.accessToken;
              console.log(token);

              // The signed-in user info.
              const user = result.user;
              console.log(user);

              // ...
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              // The email of the user's account used.
              const email = error.customData.email;
              // The AuthCredential type that was used.
              const credential = GithubAuthProvider.credentialFromError(error);
              console.log(errorCode, errorMessage, email, credential);
              // ...
            });
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    },
    async createNewScript({ state }) {
      // Add a new document in collection "cities"
      console.log("createNewScript");
      // Make new script.
      const colRef = collection(state.db, "scripts");
      let name = "Untitled";
      const docRef = await addDoc(colRef, {
        name: name,
        scriptText: "-- Lua code here",
        userId: state.user.uid,
        createdDate: Timestamp.now(),
        updatedDate: Timestamp.now(),
        isPublic: false,
      });
      // Append to user index.
      let docId = docRef.id;
      const indexRef = doc(state.db, "scripts-user-index", state.user.uid);
      let indexDocData = {
        name: name,
        id: docId,
        createdDate: Timestamp.now(),
        isPublic: false,
      };
      setDoc(indexRef, { [docId]: indexDocData }, { merge: true });
      router.push({ name: "editor", params: { id: docRef.id } });
    },
    async loadMyScripts({ state }) {
      // Add a new document in collection "cities"
      console.log("loadMyScripts", state.user.uid);
      const colRef = collection(state.db, "scripts");
      const q = query(
        colRef,
        where("userId", "==", state.user.uid),
        orderBy("createdDate", "desc"),
        limit(limitScriptLoadNum)
      );
      const querySnapshot = await getDocs(q);
      let scripts = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        let data = {
          data: doc.data(),
          key: doc.id,
        };
        scripts.push(data);
      });
      state.userScripts = scripts;
    },
    async loadEditById({ state }, id) {
      console.log("loadEditById", id);

      state.scriptLoaded = false;
      state.scriptDoc = null;

      const docRef = doc(state.db, "scripts", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        state.scriptDoc = docSnap.data();
        state.scriptLoaded = true;
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        state.scriptLoaded = false;
      }
    },
    async openFile({ state }) {
      if (supported) {
        console.log("Using the File System Access API.");
      } else {
        console.log("Using the fallback implementation.");
      }

      // Options are optional. You can pass an array of options, too.
      const options = {
        // List of allowed MIME types, defaults to `*/*`.
        // mimeTypes: ["image/*"],
        // List of allowed file extensions (with leading '.'), defaults to `''`.
        extensions: [".lua", ".codewarbler"],
        // Set to `true` for allowing multiple files, defaults to `false`.
        multiple: false,
        // Textual description for file dialog , defaults to `''`.
        description: "Lua or CodeWarbler files",
        // Suggested directory in which the file picker opens. A well-known directory or a file handle.
        // startIn: "downloads",
        // By specifying an ID, the user agent can remember different directories for different IDs.
        id: state.fileSystemProjectId,
        // Include an option to not apply any filter in the file picker, defaults to `false`.
        excludeAcceptAllOption: false,
      };

      const blob = await fileOpen(options);
      console.log(blob);
      state.fileHandle = blob.handle;
      const file = await state.fileHandle.getFile();
      state.fileText = await file.text();
      state.editorReference.getModel().setValue(state.fileText);
      console.log(state.fileText);
    },
    async updateText({ commit, dispatch }, value) {
      commit("setFileText", value);
      return dispatch("saveFile");
    },
    async updateScriptToFirestore({ state }, value) {
      // Update doc
      state.scriptDoc.scriptText = value.scriptText;
      let docId = value.id;
      const docRef = doc(state.db, "scripts", docId);
      let update = {
        updatedDate: Timestamp.now(),
        scriptText: value.scriptText,
      };
      await setDoc(docRef, update, { merge: true });

      // Update index
      const indexRef = doc(state.db, "scripts-user-index", state.user.uid);
      let indexDocData = {
        id: docId,
        updatedDate: Timestamp.now(),
      };
      setDoc(indexRef, { [docId]: indexDocData }, { merge: true });

      // const indexRef = doc(state.db, "scripts-user-index", state.user.uid);
      // let indexDocData = {
      //   name: name,
      //   id: docId,
      //   createdDate: Timestamp.now(),
      //   isPublic: false,
      // };
      // setDoc(indexRef, { [docId]: indexDocData }, { merge: true });
    },
    // async saveFile({ state, dispatch }) {
    //   if (!state.fileHandle) {
    //     // No fileHandle, save as new.
    //     return dispatch("saveFileAsNew");
    //   }
    //   const blob = new Blob([state.fileText], {});
    //   console.log(blob);
    //   await fileSave(
    //     blob,
    //     {
    //       fileName: state.fileHandle.name,
    //       description: "Lua file",
    //       extensions: ["lua"],
    //     },
    //     state.fileHandle
    //   );
    // },
    // async saveFileAsNew({ state }) {
    //   const blob = new Blob([state.fileText], {
    //     type: "text/x-lua",
    //   });
    //   let newFileHandler = await fileSave(
    //     blob,
    //     {
    //       fileName: "Untitled.lua",
    //       description: "Lua file",
    //       extensions: [".lua"],
    //       id: state.fileSystemProjectId,
    //     },
    //     null
    //   );
    //   console.log(newFileHandler);
    //   state.fileHandle = newFileHandler;
    // },
    // async saveToDatabase({ state }) {
    //   let data = {
    //     title: "Untitled",
    //     data: state.fileText,
    //     is_private: true,
    //   };
    //   fetch("http://127.0.0.1:8000/api/scripts/", {
    //     method: "POST",
    //     body: JSON.stringify(data),
    //   }).then((data) => {
    //     console.log(data);
    //   });
    // },
  },
});
