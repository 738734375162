<template>
  <Editor v-if="$store.state.scriptLoaded" />
</template>

<script>
import Editor from "@/components/Editor.vue";
export default {
  name: "EditorContainer",
  components: { Editor },
  async mounted() {
    console.log("Editor container");
    await this.$store.dispatch("connectToFirestore", this.$route.params.id);
    this.$store.dispatch("loadEditById", this.$route.params.id);
  },
};
</script>

<style></style>
