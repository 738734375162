const SCRIPT_END_CODE = "SCRIPTEND---73";
const VOLT_TRACE_START = "V::";

export default class Crowser {
  constructor() {
    this.name = "Crowser";
    this.port = null;
    this.reader = null;
    this.readableStreamClosed = null;
    this.connected = false;
    this.onPortConnected = (port) => {
      console.log("onPortConnected", port);
    };
    this.onScriptReceived = (value) => {
      console.log("onScriptReceived", value);
    };
    this.onPortDisconnect = (port) => {
      console.log("onPortDisconnect", port);
    };
    this.onForcedForget = () => {};
    this.outputText = "";
    this.outputBuffer = "";

    this.currentScript = "";

    this.volts = [];
    this.writingFile = false;

    this.firmwareVersion = "";
  }

  init() {
    navigator.serial.addEventListener("connect", (e) => {
      console.log("connected", e);
      // Connect to `e.target` or add it to a list of available ports.
    });

    navigator.serial.addEventListener("disconnect", (e) => {
      console.log("disconnected", e);
      this.onPortDisconnect(e.target);
      // Remove `e.target` from the list of available ports.
    });

    navigator.serial.getPorts().then((ports) => {
      // Initialize the list of available ports with `ports` on page load.
      console.log("ports", ports);
      // this.readPort(ports[0]);

      if (ports.length > 0) {
        this.openPort(ports[0]);
      } else {
        console.log("No ports available.");
      }
    });
  }

  connect() {
    console.log("connect");
    let _this = this;

    navigator.serial
      .requestPort()
      .then((port) => {
        console.log(port);
        // Connect to `port` or add it to the list of available ports.
        _this.openPort(port);
      })
      .catch((e) => {
        console.log(e);
        // The user didn't select a port.
      });
  }

  async disconnect() {
    console.log("closePort", this.port);
    try {
      this.onForcedForget();
      await this.port.forget();
    } catch (error) {
      console.log(error);
    }
  }

  startLogger() {
    let _this = this;
    this.loggingInt = setInterval(function () {
      if (_this.writingFile) return;
      _this.write(
        `print('${VOLT_TRACE_START}['..input[1].volts..','..input[2].volts..','..output[1].volts..','..output[2].volts..','..output[3].volts..','..output[4].volts..']')`
      );
    }, 50);
  }

  async openPort(port) {
    console.log("openPort", port);
    await port.open({ baudRate: 115200, bufferSize: 16777216, stopBits: 2 });
    let _this = this;
    this.port = port;
    this.onPortConnected(port);
    this.startLogger();
    this.getCurrentFirmwareVersion();
    while (port.readable) {
      const textDecoder = new window.TextDecoderStream();
      this.readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      this.reader = textDecoder.readable.getReader();
      // Listen to data coming from the serial device.
      let _true = true;
      while (_true) {
        const { value, done } = await this.reader.read();
        // console.log(value, done);
        if (done) {
          console.log("done!!!");
          // Allow the serial port to be closed later.
          this.reader.releaseLock();
          break;
        }
        // Look for special log messages (i/o values, firmware version)
        if (value.startsWith(VOLT_TRACE_START)) {
          try {
            let data = value.split(VOLT_TRACE_START)[1];
            this.volts = JSON.parse(data);
            continue;
          } catch (e) {
            console.warn(e);
          }
        }
        if (value.startsWith("^^version('")) {
          let re = /\^\^version\('(.*?)'\)/g;
          let result = re.exec(value);
          if (result.length > 1) {
            this.firmwareVersion = result[1];
          }
        }

        if (_this.collectOutputToBuffer) {
          this.outputBuffer = this.outputBuffer + value;
        } else {
          this.log(value);
        }
        if (this.outputBuffer.includes(SCRIPT_END_CODE)) {
          this.outputBuffer = this.outputBuffer.replace(SCRIPT_END_CODE, "");
          this.logOutputBuffer();
        }
      }
    }
  }

  log(text) {
    console.log("log: ", text);
    this.outputText = `${text}\r\n` + this.outputText;
  }

  async getCurrentScript() {
    this.collectOutputToBuffer = true;
    console.log(this.collectOutputToBuffer);
    this.outputBuffer = "";
    await this.write("^^p");
    await this.write(`print('${SCRIPT_END_CODE}')`);
  }

  logOutputBuffer() {
    this.currentScript = this.outputBuffer;
    this.log(this.outputBuffer);
    this.collectOutputToBuffer = false;
    this.onScriptReceived(this.currentScript);
  }

  getCurrentFirmwareVersion() {
    this.write("^^v");
  }

  async write(input) {
    // console.log("write");
    let port = this.port;
    this.writer = port.writable.getWriter();
    let command = `${input}\r\n`;
    // console.log("command", command);
    // this.log(command);
    const data = new TextEncoder().encode(command);
    await this.writer.write(data);

    // Allow the serial port to be closed later.
    this.writer.releaseLock();
  }

  async writeFile(text, install = false) {
    // Writes the file to crow storage. Equivalent to u filename.lua in druid.
    this.writingFile = true;
    console.log("writeFile");
    let port = this.port;
    const writer = port.writable.getWriter();

    // Force line ending to be the same for splitting.
    text = text.replace(/\r\n/g, "\n"); // replace every \r\n with \n

    let lines = text.split("\n");

    lines.unshift("^^s"); // Start of file.
    if (install) {
      // Install and run it.
      lines.push("^^w"); // End of file.
    } else {
      // Execute (ie run the file, don't install it)
      lines.push("^^e"); // End of file.
    }

    for (const line of lines) {
      let command = `${line}\r\n`;
      if (command.length % 64 == 0) {
        command = command + "\n"; // Line length can't be multiples of 64. Why?
      }
      // console.log(command);
      const data = new TextEncoder().encode(command);
      await writer.write(data);
      // Sleep, because crow needs time ... apparently.
      await new Promise((r) => setTimeout(r, 10));
    }

    // Allow the serial port to be closed later.
    writer.releaseLock();

    this.writingFile = false;
  }

  logOutput() {
    this.write("print('1: '..input[1].volts)");
  }
}
