<template>
  <div>
    <h1>Home</h1>
    <Authentication />
    <button @click="$store.dispatch('createNewScript')">New</button>

    <ScriptList v-if="$store.state.isAuthenticated" />
  </div>
</template>

<script>
import Authentication from "@/components/Authentication.vue";
import ScriptList from "@/components/ScriptList.vue";

export default {
  components: { Authentication, ScriptList },
};
</script>

<style></style>
